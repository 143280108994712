#sld {
  width: 100%;
  height: 35px;
  border: 2px solid #0fa10f;
  padding: 0 10px;
  font-size: 20px;
}

.tldlist li {
  float: left;
  margin-right: 30px;
}



.tldlist {
  display: block;
  padding: 12px 15px 15px;
  overflow: hidden;
  font-size: 18px;
}

.tldblock {
  width: 100%;
  border: 1px solid #9b9b9b;
  overflow: hidden;
  margin-top: 18px;
  margin-bottom: 30px;
}

.tldblock div:first-child {
  background: #b1b0b0;
  height: 25px;
  color: white;
}

#checkall {
  margin-top: 6px;
  margin-left: 6px;
}

label[for=checkall] {
  margin-top: 3px;
  position: absolute;
  margin-left: 5px ;
}

#table td {
  padding: 5px 0;
}

#table {
  width: 100%;
}

#table tr {
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
}

#table tr:first-child {
  border-top: 2px solid #ccc;
}

#table tr:last-child {
  border-bottom: 2px solid #ccc;
}

#table tr td:first-child {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

#table tr.available, #table tr.available td:first-child {
  color: #0fa10f;
}

#table tr.notavailable, #table tr.notavailable td:first-child {
  color: red;
}

#table tr.available .status {
  font-style: italic;
}

#result {
  margin-bottom: 33px;
}

#whoisresult {
  display: none;
  background: none repeat scroll 0% 0% #F5F5F5;
  border-radius: 5px;
  border: 2px solid #23B223;
  margin-bottom: 20px;
  padding: 20px;
  overflow: auto;
}

.wois_dom {
  border-bottom: 1px solid #c0bebe;
  padding-bottom: 15px;
  font-weight: normal;

}

.linker {
  text-align: right;
}

.sep {
  width: 20px;
  height: 20px;
  background: #22B222;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

}

.dom_w, .dom_w:hover {
  position: relative;
  margin-right: -10px;
  z-index: 30;
  background: #22B222;
  color: #fff;
  font-size: 12px;
  background: #22b222;
  padding: 3px 9px;
}

.cler {
  width: 24px;
  height: 21px;
  position: relative;
  z-index: 20;
  float: right;
  overflow: hidden;
  margin-right: -14px;
  margin-top: 3px;
}

.backs {
  background: url('../img/ajax-loader-green.gif') no-repeat 50% 50%;
}

.alert_icons_block .box {
  margin: 0px;
  width: 391px;
}

.alert_icons_block {
  padding: 10px 0px 0px;
}

.ttt:hover {
  background: #22B222;
}

.tldlist label, .tldblock label{
  margin-bottom: 0;
  font-weight: normal;
}
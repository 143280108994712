@import "misho/_contact";

.bord {
  margin: 0 0 25px 0;
  padding: 10px 0 5px!important;
  border-bottom: 1px solid #e7e6e6;
}

blockquote {
  font-size: 16px;
  color: #444;
  font-style: italic;
  background: #f5f5f5;
  border-left: 5px solid #22b222;
  padding: 16px 29px 26px 29px;
  margin-bottom: 40px;
}
.full_width ol.nobh li {
  font-weight: normal;
  padding: 7px 0 25px 0;
  margin-left: 15px;
  list-style-type: decimal;
}
.arrows_list li {
  font-weight: normal;
  position: relative;
  padding: 7px 0 7px 30px;
  background: none;
  list-style: none!important;
}
ul.arrows_list li:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  background: #22b222 url(../../img/sprite.png) 0 -189px no-repeat;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  behavior: url(../css/PIE.htc);
}
ol.nob li {
  font-weight: normal;
  padding: 7px 0 7px 0;
  margin-left: 15px;
  list-style-type: decimal;
}
ol.nobh li {
  font-weight: normal;
  padding: 7px 0 25px 0;
  margin-left: 15px;
  list-style-type: decimal;
}
.bordered-image{
  border: 5px solid #f6f3f3;
}

/* GlobalR.com Home Page*/
.home-exclusive h1 {
  text-align: center;
  margin-bottom: 30px;
}
.home-icon{
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background: url(../../img/ikonk.png);
}
.home-exclusive{
  margin-bottom: 60px;
}
.home-exclusive h1{
  text-align: center;
  margin-bottom: 30px
}
.features{
  text-align: center;
  padding: 15px 0;
  position: relative;
}
.features:before{
  content: '';
  display: block;
  width: 80%;
  height: 0;
  position: absolute;
  top: 30px;
  left: 0;
  border-top: 1px solid #d6d5d5;
  margin-left: 10%;
}
.fetre{
  padding: 0 6px;
  background: #fff;
  position: relative;
  z-index: 2;
  font-weight: bold;
  font-size: 17px;
  color: #434242;
}
.ge-global{
  background-position: -80px 0;
}
.diamond-global{
  background-position: -160px 0;
}
.note-global{
  background-position: -240px -3px;
}
.charger-global{
  background-position: 160px 0;
}
.star-global{
  background-position: 80px 0;
}
.home-text{
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  color: #626363;
  max-width: 256px;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5;
}
.home-text p {
  margin: 0 0 25px;
}
.green-button{
  text-align: center;
  margin-top: 25px;
  margin-bottom: 60px;
}
.green-button a{
  margin: 0 auto;
  max-width: 375px;
  font-size: 18px;
  text-decoration: none;
  color: white;
  font-family: 'PT Sans Bold', sans-serif;
}
.white-gradient {
  overflow: hidden;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+0,ebebeb+48,e5e5e5+100 */
  background: #f2f2f2;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ4JSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #f2f2f2 0%, #ebebeb 48%, #e5e5e5 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f2f2f2), color-stop(48%, #ebebeb), color-stop(100%, #e5e5e5));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f2f2f2 0%, #ebebeb 48%, #e5e5e5 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f2f2f2 0%, #ebebeb 48%, #e5e5e5 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f2f2f2 0%, #ebebeb 48%, #e5e5e5 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f2f2f2 0%, #ebebeb 48%, #e5e5e5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#e5e5e5', GradientType=0);
  /* IE6-8 */
  background: #ededed;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#f9f9f9), to(#ededed));
  background: -webkit-linear-gradient(#f9f9f9, #ededed);
  background: -moz-linear-gradient(#f9f9f9, #ededed);
  background: -ms-linear-gradient(#f9f9f9, #ededed);
  background: -o-linear-gradient(#f9f9f9, #ededed);
  background: linear-gradient(#f9f9f9, #ededed);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #C3C3C3;
  font-weight: bold;
  font-style: italic;
  color: #0fa00f;
  position: relative;
  -webkit-box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.05);
}
.white-gradient.sm {
  font-size: 13px;
  padding: 5px 13px;
  display: inline-block;
}
.white-gradient.btn_l {
  color: #0fa00f;
  padding: 10px 30px !important;
  display: block;
  text-align: center;
  width: auto !important;
  height: auto !important;
  display: inline-block !important;
  font: bold italic 16px 'PT Sans' !important;
}
.white-gradient:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5e5e5+0,ebebeb+52,f2f2f2+100 */
  background: #e5e5e5;
  /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1ZTVlNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUyJSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMmYyZjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #e5e5e5 0%, #ebebeb 52%, #f2f2f2 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e5e5), color-stop(52%, #ebebeb), color-stop(100%, #f2f2f2));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #e5e5e5 0%, #ebebeb 52%, #f2f2f2 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #e5e5e5 0%, #ebebeb 52%, #f2f2f2 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #e5e5e5 0%, #ebebeb 52%, #f2f2f2 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #e5e5e5 0%, #ebebeb 52%, #f2f2f2 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5e5e5', endColorstr='#f2f2f2', GradientType=0);
  /* IE6-8 */
  text-decoration: none;
  cursor: pointer !important;
}
.sld-logo {
  margin-right: 25px;
  margin-bottom: 40px;
  padding: 15px 30px;
  width: 205px;
  height: 115px;
}
@media (max-width: 1056px){
.sld-logo {
  margin: 0 auto 10px!important;
  max-width: 100%;
  width: auto!important;
  float: none!important;
}
}

/*About Page*/

.about-icons{
  box-shadow: #22b222 0 0 0 5px inset;
  position: absolute;
  top: 0;
  left: 10px;
  display: block;
  width: 51px;
  height: 51px;
  background: url(../../img/icons.png) 0 0 no-repeat;
  border-radius: 26px;
}
.up-to-icon h4 {
  padding-left: 65px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: rgb(98, 99, 99);
}
.up-to-icon h4+p{
  padding: 0 10px 10px 65px;
  margin-bottom: 0px;
  line-height: 1;
  font-size: 11px;
  text-transform: uppercase;
}


.green-become{
  color:#0fa00f;
}
html, body {
  height: 100%;
}

/* Footer GlobalR */

.footer .container-fluid {
  max-width: 1040px!important; ;
}
.without-footer{
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -125px;
  overflow: hidden;
}
.without-footer:after {
  content: "";
  display: block;
}
.footer, .without-footer:after {
  height: 125px;
}

.green-zone {
  background: #0fa00f url(../../img/text_bar2.png);
  height: 66px;
  margin-bottom: 1px;
  position: relative;
}
.green-zone .container {
  height: 66px;
}
.green-zone:before {
  content: '';
  display: block;
  width: 100%;
  height: 66px;
  position: absolute;
  top: 0;
  left: 0;
}
.subscribe {
  color: #fff;
  line-height: 66px;
  padding-left: 0;
  margin-bottom: 0;
  float: left;
}

.subscribe:after {
  content: '';
  padding-right: 12px;
  background: url(../../img/sprite.png) 6px -10px no-repeat;
}
.input-xb {
  font: 13px 'PT Sans';
  color: #626363;
  border: 1px solid #c0bebe;
  background: #f6f3f3;
  padding: 5px 10px;
  position: relative;
}
.input-xb.subscribe-input {
  margin: 7px 5px 7px 20px;
  width: 100%;
  max-width: 175px;
  height: 55px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media (max-width: 1199px){
  .input-xb.subscribe-input {
    margin: 7px 5px 7px 0;
  }
}
.footer ul.social li {
  float: left;
  min-height: 66px;
  font: bold 14px/66px 'PT Sans';
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.footer ul.social li a, .footer ul.social li a:visited {
  color: #fff;
  display: block;
  float: left;
  position: relative;
  padding: 0 16px 0 46px;
}
.footer ul.social li a:before, .footer ul.social li a:visited:before {
  content: '';
  background: url(../../img/sprite.png) 0 -31px no-repeat;
  z-index: 2;
  position: absolute;
  top: 19px;
  left: 16px;
  display: block;
  width: 22px;
  height: 26px;
}
.footer ul.social li.twitter a:before {
  background-position: -36px -31px;
}
.footer ul.social li.linkedin a:before {
  background-position: -73px -31px;
}
.footer ul.social li.google a:before {
  background-position: -184px -31px;
}
.footer ul.social li a:hover, .footer ul.social li a:visited:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  .input-xb.subscribe-input {
    margin: 7px 5px 7px 0;
  }
}
@media (max-width: 866px) {
  .input-xb.subscribe-input {
    max-width: 125px;
  }
}
@media(max-width: 767px){
  .input-xb.subscribe-input {
    max-width: 185px;
  }
  .green-form{
    text-align: center;
    margin-top: 7px;
  }
  .green-zone{
    height: auto;
  }
  .green-small{
    float: none!important;
    width: 250px;
    margin: 0 auto;
  }
  .footer-hidden{
    display: none!important;
    visibility: none;
  }
}
.copyright {
  border-top: 1px solid #e7e6e6;
  font-size: 11px;
}
.copyright a {
  color: #626363;
}
.copyright ul {
  display: inline-block;
  padding: 10px 0 0 20px;
  margin: 0;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
 body .public-page   {
  font-size: 16px;
  padding-bottom: 50px;
  line-height: 150%;
  margin: 0 0 10px;
   color:rgb(98, 99, 99);

}
body .public-page  h3 {
  color: #333;
  font-size: 18px;
  padding-bottom: 14px;
  font-weight: bold;
}

 .public-page h2, .h2 {
  font-size: 30px;
  color:rgb(98, 99, 99);
}
 .public-page .nob li , .arrows_list li {
   color:rgb(98, 99, 99);
   font-size: 16px;
 }
.full_width li {
  list-style: none;
}

/* POlicies */

.full_width li a {
  list-style: none;
  text-decoration: none;
}

.services_option .left_list li {
  border: none;
  width: 240px;
  padding: 18px 0 0px 20px;
}

.features2_block ul li {
  position: relative;
  float: left;
}

.features2_block ul li.privacy .circle {
  background-position: -767px 0;
}
.features2_block ul li:hover .circle {
  top: 5px;
  background-position: 0 -56px;
  -webkit-box-shadow: #22b222 0 0 0 5px inset;
  -moz-box-shadow: #22b222 0 0 0 5px inset;
  box-shadow: #22b222 0 0 0 5px inset;
}
.features2_block ul li .circle {
  position: absolute;
  top: 15px;
  left: 15px;
  display: block;
  width: 51px;
  height: 51px;
  background: url(../../img/icons.png) 0 0 no-repeat;
  -webkit-transition: top .4s linear;
  -moz-transition: top .4s linear;
  -o-transition: top .4s linear;
  -ms-transition: top .4s linear;
  transition: top .4s linear;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;

}
.features2_block ul li {
  position: relative;
  float: left;
}
.services_option .left_list li .tlink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 1000;
  height: 90%;
}

.features2_block ul li .circle_link {
  visibility: hidden;
  position: absolute;
  top: 5px;
  left: 10px;
  display: block;
  width: 51px;
  height: 51px;
  color: #fff;
  text-indent: -999em;
  z-index: 10;
}

.services_option .left_list li:hover h4 {
  color: #0fa00f;

}
.services_option .left_list li h4 {
  color: #333;
  font-size: 14px;
  padding: 10px 0 0 70px;
  margin: 0;
  border: none;
}
.services_option ul li h4 + p {
  padding: 0 10px 10px 70px;
  margin-bottom: 0px;
}
.features2_block ul li:hover:after {
  visibility: visible;
}

.features2_block ul li p span {
  text-transform: uppercase;
  font-size: 11px;
}
.services_option .left_list {
  float: left;
  width: 480px;
  padding: 0 0 90px 0;
  list-style: none;
}
.services_option .left_list:hover {

  list-style: none;
}
.services_option .right_desc {
  float: right;
  width: 480px;
  padding: 20px 0 90px 0;
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .services_option .right_desc {
    width: 290px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .services_option .right_desc {
    width: 460px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 479px){
  .services_option .left_list li {
    width: 275px;
  }
}
@media only screen and (max-width: 479px){
  .services_option .right_desc {
    width: 280px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .services_option .left_list {
    width: 300px;
    padding-bottom: 20px;
  }
}
.features2_block ul li.privacy .circle {
  background-position: -767px 0;
}
.features2_block ul li.terms .circle {
  background-position: -826px 0;
}
.features2_block ul li.abuse .circle {
  background-position: -885px 0;
}
.features2_block ul li.launchpolicy .circle {
  background-position: -944px 0;
}
.features2_block ul li.ampolicy .circle {
  background-position: -1003px 0;
}
.features2_block ul li.gepolicy .circle {
  background-position: -1062px 0;
}
.features2_block ul li.complaint .circle {
  background-position: -1121px 0;
}
.social_icons ul li {
  float: left;
  padding-right: 18px;
  text-indent: -999em;
  width: 56px;
}
.social_icons ul li a.facebook {
  background-position: 0 0;
}
.social_icons ul li a {
  display: block;
  float: left;
  background: url(../../img/share_icons.png) 0 0 no-repeat;
  width: 36px;
  height: 36px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.social_icons ul li a.tweeter {
  background-position: -48px 0;
}

/* for registrars */

.features6_block ul {
  float: none;
  width: auto;
  margin: 10px 0 30px 0;
}
.features2_block ul li {
  position: relative;
  float: left;
}
.features2_block ul li {
  position: relative;
  float: left;
  width: 232px;
  padding: 20px 5px 0 10px;
}
.features6_block ul li {
  border-right: none;
  padding-right: 5px;
  padding-left: 25px;
  text-align: left;
}
.features2_block li {
  display: block;
}
.features2_block ul li.easy .circle {
  background-position: -172px 0;
}
.features2_block ul li .circle {
  position: absolute;
  top: 15px;
  display: block;
  width: 51px;
  height: 51px;
  background: url(../../img/icons.png) 0 0 no-repeat;
  -webkit-transition: top .4s linear;
  -moz-transition: top .4s linear;
  -o-transition: top .4s linear;
  -ms-transition: top .4s linear;
  transition: top .4s linear;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
}
.features2_block ul li .circle_link {
  visibility: hidden;
  position: absolute;
  top: 5px;
  left: 10px;
  display: block;
  width: 51px;
  height: 51px;
  color: #fff;
  text-indent: -999em;
  z-index: 10;
}
.features2_block a {
  text-decoration: none;
}
.features2_block ul li h4 {
  padding-left: 65px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
}
.features2_block ul li h4 + p {
  padding: 0 10px 10px 70px;
  margin-bottom: 0px;
  line-height: 1;
}
.features2_block ul li:after {
  content: '';
  visibility: hidden;
  position: absolute;
  top: 60px;
  left: 0;
  display: block;
  width: 73px;
  height: 14px;
  background: url(../../img/shadow2.png) 0 0 no-repeat;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}
.features2_block ul li.pre .circle {
  background-position: -472px 0;
}
.features2_block ul li.updates .circle {
  background-position: -413px 0;
}
.features2_block ul li.ultra .circle {
  background-position: -294px 0;
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .features6_block ul {
    width: auto;
  }
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .features2_block ul li {
    width: 175px;
    padding: 20px 0 0 15px;
  }
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .features6_block ul li {
    width: 175px;
    padding-left: 18px;
  }
}

/*   Contact  */

.bc_list ul li {
  list-style: none;
  float: left;
  width: 300px;
  padding: 300px 10px 65px 10px;
  position: relative;
}
.bc_list ul li .bwWrapper,
.bc_list ul li .cWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  -webkit-transition: top .4s linear;
  -moz-transition: top .4s linear;
  -o-transition: top .4s linear;
  -ms-transition: top .4s linear;
  transition: top .4s linear;
  border: 5px solid #f6f3f3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.bc_list ul li img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter ….3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  transition: 0.3s ease-in;
}
.bc_list ul li p {
  text-align: center;
}
.bc_list ul li .bwWrapper img,
.bc_list ul li .cWrapper,
.bc_list ul li .bwWrapper canvas,
.bc_list ul li .cWrapper canvas {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.bc_list ul li h4 {
  text-align: center;
  padding-bottom: 13px;
  font-weight: bold;
}
.bc_list ul li:after {
  content: '';
  visibility: hidden;
  position: absolute;
  top: 260px;
  left: 85px;
  display: block;
  width: 219px;
  height: 40px;
  background: url(../../img/shadow3.png) 0 0 no-repeat;
  -webkit-transition: all .4s linear;
  -moz-transition: all .4s linear;
  -o-transition: all .4s linear;
  -ms-transition: all .4s linear;
  transition: all .4s linear;
}
.bc_list ul li:hover:after {
  visibility: visible;
}
.bc_list ul li:hover .bwWrapper,
.bc_list ul li .cWrapper {
  top: 0;
}
.full_width ul {
  padding: 0;
}
.full_width li {
  list-style: none;
}
.bc_list ul li:hover .bwWrapper,
.bc_list ul li .cWrapper {
  top: 0;
}
.featured_list ul li .bwWrapper {
  width: auto;
}
.full_width .bwWrapper img {
  height: auto;
}
@media only screen and (min-width: 954px) {
  .bc_list ul li {
    width: 33.333%;
  }
  .bc_list ul li .bwWrapper{
    left:60px;

  }
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .bc_list ul li:nth-child(2) {
    float: right;
  }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .bc_list ul li:nth-child(2) {
    float: none;
  }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .bc_list ul li {
    margin: 0 auto;
    float: none;
  }
}
@media only screen and (max-width: 767px) and (min-width: 480px) {
  .full_width .bwWrapper img {
    height: auto;
  }
}
.dataTable.table > tbody > tr > td:first-child {
  font-weight: bold;
  font-size: 16px;
}

/*  Clear Code  */

.round-icons h4{
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
}
.round-icons h4+p{
  margin-bottom: 20px;
  line-height: 1;
  font-size: 11px;
}
.round-icons::before{
  content: " ";
  display: block;
  float: left;
  background: url(../../img/icons.png) 0 0 no-repeat;
  width: 51px;
  height: 51px;
  margin-right: 15px;
  box-shadow: inset 0 0 0 5px #22b222;
  border-radius: 26px;
}
.nout::before{
  background-position: -172px 0;
}
.hourglass::before{
  background-position: -472px 0;
}
.instruments::before{
  background-position: -413px 0;
}
.resize::before{
  background-position: -294px 0;
}
/* End of Clear Code */

body div.bottom-offset{
  margin-bottom:60px;
}
@media(max-width: 767px) {
  body div .tariff-row h2.labeled {
    margin-top: 0;
  }

}
.dataTables_wrapper .dataTables_filter input {
  padding-left: 4px;
}

.ext-me-bold {
  font-size: 40px!important;
  font-weight: bold!important;
  color: #ffffff!important;
}

.exportDomainList {
  display: inline;
}

.nsBulkUpdate {
  display: inline;
}

.renewBulkUpdate {
  display: inline;
}

.attention-error {
  //top: 9px;
  //float: right;
  //right: 25px;
  display: block;
  position: absolute;
  display: block;
  height: 27px;
  width: 27px;
  background: url(../../../img/icons/error.svg) no-repeat;
}

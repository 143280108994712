.gray-divider{
  border-width: 4px;
  border-color: #ccc;
}
.invoice {
  .info-colum {
    float: left;
    text-align: left;
    padding: 0 40px;
    border-right: 1px solid #ccc;
    .method-icon{
      top:auto;
    }

    &:last-child{
      padding: 0 0 0 40px;
      border-right: 0;
    }
  }
  .well{
    margin-top: 20px;
  }
  .list-row {

    & > div {
      padding: 10px 0;
    }

  }
  padding: 35px;
  .dl-horizontal dt {
    text-align: left;
  }
  .action-row {
    margin-top: 60px;
    .list-inline {
      display: block;
      background: rgba(0, 0, 0, 0.05);
      padding: 5px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      & > li {
        padding: 3px;
      }
    }
    .btn-xs {
      padding: 0 10px;
      .fa {
        top: -1px;
        position: relative;
      }
    }
  }
  .invoice-status {
    font-size: 40px;
    padding: 8px 20px;
    border: 3px solid;
    text-transform: uppercase;
    line-height: 1;
    &.unpaid {
      color: $brand-danger;
      border-color: $brand-danger;
    }
    &.paid {
      color: $brand-primary;
      border-color: $brand-primary;
    }
    &.annulled {
      color: #aaaaaa;
      border-color: #aaa;
    }
  }
}

.bootstrap-select > .dropdown-toggle {
  padding-left: 10px;
}

.method-icon {
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
  position: absolute;
  background-color: #F6F3F3;
  height: 24px;
  width: 40px;
  top: 10px;
  &:after {
    height: 15px;
    width: 18px;
    background-size: cover;
    display: block;
    content: "";
    margin: 3px auto;

  }
  &.bank:after {
    background-image: url("../../img/payment/bank.png");
  }
  &.alipay:after {
    background-image: url("../../img/payment/alipay.png");
  }
  &.unionpay:after {
    background-image: url("../../img/payment/unionpay.png");
  }
  &.tenpay:after {
    background-image: url("../../img/payment/tenpay.png");
  }
  &.master:after {
    background-image: url("../../img/payment/master.png");
    width: 33px;
    height: 18px;
  }
  &.visa:after {
    background-image: url("../../img/payment/visa.png");
    width: 33px;
    height: 18px;
  }
  &.PayPal:after {
    background-image: url("../../img/payment/paypal.png");
    width: 33px;
    height: 18px;
  }
  &.balance:after {
    background-image: url("../../img/payment/balance.png");
    width: 33px;
    height: 18px;
  }
}

.method-text {
  position: relative;
  left: 57px;
  font-weight: normal;
}

.bootstrap-select .dropdown-menu.inner {
  .method-icon {
    top: 2px;
    left: 11px;
  }
}

.bootstrap-select.btn-group .dropdown-menu li a {
  outline: none;
}

.total-top {
  font-weight: bold;
  font-size: 24px;
}

.bordered {
  border: 4px solid #ccc;
  overflow: hidden;
  padding: 15px;
  &.orange {
    border-color: $brand-warning;
  }
}

.total-row {
  border-top: 3px solid #ccc;
  padding-right: 20px;
  padding-top: 15px;
  margin: -1px -19px 0;

}
.total-text{
  font-size: 30px;
  margin-bottom:20px;
}
.modal-body{
  min-height: 355px;
}
.topModal{
  z-index: 1052;
}
.invoice-row{
  cursor: pointer;
  &:hover{
    background: darken(#f6f3f3, 3%);
  }

}
.table-striped > tbody > tr.invoice-row:hover:nth-of-type(odd){
  background: darken(#f6f3f3, 3%);
}
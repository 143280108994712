.fill-container {
  margin: 80px auto;
  overflow: hidden;
  .text-amount {
    float: left;
    line-height: 66px;
    padding-right: 20px;
  }
  .input-amount {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    float: left;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: rgba(0, 0, 0, 0.05) solid 10px;
    height: 65px;
    width: 300px;
    max-width: 100%;
    .wrapper {
      border: 1px $input-border solid;
      height: 100%;
      width: 100%;
      position: relative;
      .overwrite-field, input[name=amount] {
        width: auto;
        background: white;
        height: 100%;
        padding: 0 50px ;
        text-align: center;
        font-size: 22px;
        line-height:44px;
        font-weight: bold;
        max-width: 100%;
      }

      .adding {
        position: absolute;
        height: 100%;
        width: 44px;
        line-height: 44px;
        left: 0;
        top:0;
        cursor: pointer;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        background: -webkit-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
        background: -moz-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
        background: -o-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
        background: -ms-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
        background: linear-gradient(0deg, #efefef 0%, #fefefe 100%);
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;
        border: 0px solid $input-border;
        border-right-width: 1px;

        &:hover{
          background: -webkit-linear-gradient(-90deg, #efefef 0%, #fefefe 100%);
          background: -moz-linear-gradient(-90deg, #efefef 0%, #fefefe 100%);
          background: -o-linear-gradient(-90deg, #efefef 0%, #fefefe 100%);
          background: -ms-linear-gradient(-90deg, #efefef 0%, #fefefe 100%);
          background: linear-gradient(180deg, #efefef 0%, #fefefe 100%);
        }
        &:active{
          background: -webkit-linear-gradient(-90deg, #ccc 0%, #fefefe 100%);
          background: -moz-linear-gradient(-90deg, #ccc 0%, #fefefe 100%);
          background: -o-linear-gradient(-90deg, #ccc 0%, #fefefe 100%);
          background: -ms-linear-gradient(-90deg, #ccc 0%, #fefefe 100%);
          background: linear-gradient(180deg, #ccc 0%, #fefefe 100%);
        }
        &.plus {
          border: 0px solid $input-border;
          border-left-width: 1px;
          top: 0;
          left: auto;
          right: 0;

        }
      }
    }
  }

}
#amount-error {
  margin:0!important;
}
.payment-methods-list {
  .radio{
    float: left;
    padding: 15px;
  }
  label {
    &.green-border{
      border-color:$brand-primary;
    }
    background-color: white;

    &.bank {
      background: url("../../img/payment/bank.png") no-repeat 40px 7px white;
    }
    &.master {
      background: url("../../img/payment/master.png") no-repeat 43px 61% white;
    }
    &.visa {
      background: url("../../img/payment/visa.png") no-repeat 43px 61% white;
    }
    &.PayPal {
      background: url("../../img/payment/paypal.png") no-repeat 43px 61% white;
    }
    &.alipay {
      background: url("../../img/payment/alipay.png") no-repeat 43px 61% white;
    }
    &.tenpay {
      background: url("../../img/payment/tenpay.png") no-repeat 43px 61% white;
    }
    &.unionpay {
      background: url("../../img/payment/unionpay.png") no-repeat 43px 61% white;
    }
    &.balance {
      background: url("../../img/payment/balance.png") no-repeat 43px 61% white;
    }
    height: 60px;
    width: 135px;
    border: 2px $input-border solid;
    .replacer {
      top: 20px;
      left: 10px;
    }
  }
}
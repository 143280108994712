.tariff-row {
  padding-top: 15px;
  h2 {
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    background: $brand-primary;
    margin: 0;
    padding: 23px;
    line-height: 1;
    font-size: 35px;
    color: white;
    text-align: center;
    border-bottom: 2px solid #0b8045;
    &.labeled {
      margin-top: -30px;
      border-bottom: 1px solid #0b8045;
    }
  }
  .popular {
    background: #ff851b;
    color: white;
    text-transform: uppercase;
    margin: 0;
    border-top: 1px solid #e6af0b;
    border-bottom: 1px solid #e6660b;
    text-align: center;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
  }
  .block {
    -moz-border-radius: 5px;
    border-radius: 5px;
    -moz-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-radius: 0px 0px 5px 5px;
    -moz-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    border: 2px solid #ceccd3;
    border-top: 0;
    overflow: hidden;
    background: white;
    padding: 0 30px;
    .tariff-pricing {
      position: relative;
      &:after {
        content: '+';
        display: block;
        text-align: center;
        color: $brand-primary;
        font-size: 35px;
        position: absolute;
        top: 15px;
        width: 100%;
      }
      &.single-price {
        .fee {
          text-align: center;
          width: 100%;
        }
        &:after {
          content: '';
          display: none;
        }

      }

      margin: 15px 0;

      .description {
        color: rgba(51, 51, 51, 0.7);
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
      .fee-price, .deposit-price {
        font-size: 30px;
        color: #000;
        span {
          font-size: 20px;
          display: inline-block;
          top: -7px;
          position: relative;
        }
      }
      .fee {
        float: left;
      }
      .deposit {
        float: right;
      }
      .single-deposit {
        text-align: center;
      }
      .price-hint {
        background: #f4f5f7;
        color: rgba(51, 51, 51, 0.7);
        font-size: 14px;
        text-align: center;
        margin: 0 -30px;
        padding: 10px 0;
        line-height: 1;

      }
      .price-list {
        max-width: 140px;
        margin: 10px auto;
        font-size: 18px;
        overflow: hidden;
        background: url("../../img/dot.png") 50% repeat-x;
        .ext, .price-list-price{
          padding: 0 5px;
          background: white;

        }
      }
    }
  }
  .btn-tariff {

    background: $brand-primary;
    color: white;
    display: block;
    padding: 25px 0;
    font-size: 26px;
    margin: 0 -30px;
    text-align: center;
    position: relative;
    z-index: 51;
    overflow: hidden;
    height: 86px;
    text-decoration: none;
    &:hover {
      background: #0b8045;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 25px;
      background: url("../../img/button-bg.png") 50% 0;
      background-size: 100% 100%;
      left: 0;
      top: 0;
    }
  }
}

.tariff-block{
  margin: 0 auto 50px;
  max-width: 325px;
}
.mrg15-0 {
  margin: 15px 0;
}
.table-non-bordered, .modal-price {
  margin:0;
  .table > tbody > tr > td, .table > tbody > tr{
    padding: 0!important;
    margin:0;
    vertical-align: top;
  }
  .pricing-desc{
    line-height: 28px;
  }

  .table{
    thead {
      color:#333;
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px #ccc solid;
      tr {
        td {
          &:first-child{
            text-align: left;
          }

          text-align: center;
          &.active{
            background: transparent;
            color:$brand-primary;
            position: relative;
            &:after{
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              margin:0 auto;
              border-width: 12px 12px 0 12px;
              border-color: #ccc transparent transparent transparent;
              position: absolute;
              left: 50%;
              right: 50%;
              margin-left: -16px;
              bottom: -12px;
            }
          }
        }
      }
    }
    tbody{
      tr {
        td {
          &:first-child {
            padding-left: 15px!important;
          }
        }

        &.row-divider{
          border-top: 10px solid transparent !important;
          border-bottom: 10px solid transparent !important;
          &:hover{
            background: transparent;
          }
          td{
            background: #ccc;
            height: 3px!important;
            overflow: hidden;
            box-sizing: content-box;
          }
        }
        &.button-row {
          &:hover{
            background: transparent;
          }
          td {
            padding: 15px!important;

          }
        }
      }
    }
  }
  .table > tbody tr:first-child  td{
    padding-top: 10px!important;

  }

  .table-price{
    color:rgba(51, 51, 51, 0.5);
    &.active{
      color: #333;
    }

  }
  border:0;
  .table > tbody > tr > td,td,tr{
    border:0;
  }
}
.price-item{
  display: block;
  max-width: 50px;
  margin: 0 auto;
}
.pricing-desc{
  display: block;
}
.table-responsive {
  overflow-x: auto;
}

.entity-hide {
  background: transparent;
  &.legal, &.person {
    background: white;
  }
}

.entity-type {
  position: relative;
  margin: 0 -30px;
  label {

    font: 35px "PT Sans";
    text-align: center;
    position: relative;
    z-index: 16;
    span.inner {
      position: relative;
      display: block;
      border-bottom: 5px solid transparent;
      margin-right: 120px;
      margin-left: 15px;
      padding: 20px  30px;

    }
    &.person-label span.inner {
      margin-right: 15px;
      margin-left: 120px;
    }
    &:hover span.inner {
      background: white;
      border-bottom: 5px solid #0fa10f;
    }

  }

  .radio label {
    &.legal-label {
      &:hover {
        & ~ .switcher > .circle {
          left: -15px;
          transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
        }
      }
    }
    &.person-label {
      &:hover {
        & ~ .switcher > .circle {
          left: 15px;
          transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
        }
      }
    }
    margin: 0;
    padding: 0;
    .replacer, input[type=radio]:checked + .replacer {
      display: none;
    }
  }

  .circle {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: 11;
    height: 60px;
    width: 60px;
    background-color: #0fa10f;
    border-radius: 50%;
    box-shadow: 1px 2px 3px 0.00px rgba(1, 1, 1, 0.2);
    margin: 0 auto;
    position: relative;
    left: 0;

  }

  .switcher {
    margin: 15px auto 0;
    width: 155px;
    height: 60px;
    border-radius: 30px;
    background-color: #dce0e6;
    position: relative;
    z-index: 10;
    &:before {
      position: absolute;
      left: 15px;
      display: block;
      content: "";
      top: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 17px 16px 0;
      border-color: transparent #ffffff transparent transparent;
      line-height: 0px;
      _border-color: #000000 #ffffff #000000 #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      z-index: 12;
    }
    &:after {
      z-index: 12;
      display: block;
      position: absolute;
      right: 15px;
      top: 15px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 0 16px 17px;
      border-color: transparent transparent transparent #ffffff;
      line-height: 0px;
      _border-color: #000000 #000000 #000000 #ffffff;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
  }

}

@media (max-width: 993px) {
  .entity-type label {
    font-size: 25px;
  }
  .entity-type label {
    span.inner {

      margin-right: 90px;

    }
    &.person-label span.inner {
      margin-left: 90px;
    }
  }
}

@media (max-width: 769px) {
  .if-legal.divider{
    padding: 30px;
  }
  .entity-type {
    label {
      width: 50%;
    }
    span.inner {
      width: auto !important;

      margin: 0 15px !important;
      text-align: center;
    }
    .switcher {
      display: none;
    }
  }

}

.legal {
  .person-wrapper{
    display: none;
  }
  .circle {
    left: -50px;
  }
  .entity-type .radio label.legal-label {
    span.inner{
      background: white;
      border-color: transparent!important;
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: -17px;
        left: 50%;
        right: 50%;
        margin: 0 -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 16px 0 16px;
        border-color: #ffffff transparent transparent transparent;
      }
    }
    &:hover {
      & ~ .switcher > .circle {
        left: -50px;
        transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
      }
    }
  }
}

.person {
  .legal-wrapper{
    display: none;
  }
  .circle {
    left: 50px;
  }
  .entity-type .radio label.person-label {
    span.inner{
      background: white;
      border-color: transparent!important;
      &:after{
        content: "";
        display: block;
        position: absolute;
        bottom: -17px;
        left: 50%;
        right: 50%;
        margin: 0 -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 16px 0 16px;
        border-color: #ffffff transparent transparent transparent;
      }
    }
    &:hover {
      & ~ .switcher > .circle {
        left: 50px;
      }
    }
  }
}
.type-header{
  background-color: #0fa10f;
  color: #ffffff;
  font: 20px "Open Sans", sans-serif;
  margin: 0 -30px 30px;
  padding: 15px;
  line-height:0.8;
}




.block-error, .block-success{
  text-align: center;
  .status-icon{
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    color: $brand-danger;
    &:before{
      content: "";
      display: block;
      width: 88px;
      height: 88px;
      background: url("/img/icons/error.svg") no-repeat;
      background-size:cover;
      margin: 0 auto;

    }
    &:after{
      content: "Attention!";
      display: block;
    }
  }
}
.block-success{
  .status-icon{
    &:before{
      background: url("/img/icons/success.svg") no-repeat;
      background-size:cover;
    }
    &:after{
      content: "Success";
      color: $brand-primary;
      display: block;
    }
  }
}
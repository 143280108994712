//
// Wells
// --------------------------------------------------


// Base class
.well {
  min-height: 20px;
  margin-bottom: 20px;
  border:none;
  border-bottom: 3px solid #ccc;
  background: $well-bg;
  padding: 20px;
  &.without-border{
    border-bottom: 0;
  }
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }
}

// Sizes
.well-lg {
  padding: 24px;

}
.well-sm {
  padding: 9px;

}

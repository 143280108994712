.okayNav-header {
  height: 6rem;
  //background-color: #fff;
}
.okayNav a {
  color: #fff;
  font-size: 1.4rem;
  //font-weight: bold;

  display: block;
  font-size: 13px;

  //text-shadow: 0 1px 7px rgba(0,0,0,0.5);
}
.okayNav a:hover {
  // color: #546edb;
  text-shadow: 0 1px 7px rgba(0,0,0,0.5);
}
.okayNav__nav--visible a:hover {
  // color: #0e5b2a;
  text-decoration: none;
  text-shadow: 0 1px 7px rgba(0,0,0,0.5);
}
.okayNav__nav--invisible {
  background: #0e5b2a;
}
.okayNav__nav--invisible li:hover {
  background: #08411e;
}

.okayNav__nav--invisible a:hover {
  text-decoration: none;
  cursor:pointer;
  text-shadow: 0 1px 7px rgba(0,0,0,0.5);
}
.okayNav__menu-toggle span {
  background: white;
}
.icon--active{
  margin-top: 1.5px;
  background: #0e5b2a;

  &:after{
    background: #0e5b2a;
  }
  &:before{
    background: #0e5b2a;
  }
}
.okayNav__menu-toggle{
  &:after{

    content: "";
    height: 39px;
    display: block;
    position: relative;
  }
  &:before{
    content: "";
    height: 62px;
    display: block;
    position: relative;
    margin-top: -39px;
  }
  &:hover, &:hover:before,&:hover:after{
    background: #0e5b2a;
  }

}



//.okayNav-header {
//    margin-top: 7px;
//    color: white;
//    //float: right;
//    ul {
//      margin: 0;
//      &.list-inline > li {
//        padding-left: 15px;
//        padding-right: 15px;
//      }
//      li {
//        display: inline-block;
//      }
//      .icon-item {
//        a {
//          color: white;
//          text-decoration: none;
//          .fa {
//            display: block;
//            font-size: 20px;
//          }
//          text-align: center;
//          display: block;
//          float: left;
//          .description {
//            font-size: 13px;
//          }
//        }
//
//      }
//    }
//
//  }
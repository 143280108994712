.btn-outline {
  &.btn-xs{
    line-height: 22px;
    padding: 3px 12px;
  }
  background-color: transparent;
  color: inherit;
  transition: all .3s;
}

.btn-primary.btn-outline, .btn-normal.btn-outline {
  color: $brand-primary;
  border-color: $brand-primary;
  &:hover{
    background-color:$brand-primary ;
    color:white
  }
}
.btn-hint.btn-outline, .btn-hint.btn-outline {
  color: #e6af0b;
  border-color: #e6af0b;
  &:hover{
    background-color:#e6af0b ;
    color:white
  }
}
.btn-success.btn-outline {
  color: $brand-success;
}

.btn-info.btn-outline {
  color: $brand-info;
}

.btn-warning.btn-outline {
  color: $brand-warning;
}

.btn-danger.btn-outline {
  color: $brand-danger;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
  &.disabled{
    background-color: transparent;
  }
}
.disabled.btn-primary.btn-outline:hover{

  color: #0fa00f;
}
.text-hint{
  color:#e6af0b;
}
#my-domains-list{
  .text-danger, .text-warning, .text-hint{
    font-weight: bold;
  }
  .text-warning {
    color:#e6660b;
  }

}

.label-outline{
  font-weight: bold;
  font-size: 17px;
  padding: 7px;
  border: 3px solid #aaaaaa;
  text-align: center;
  line-height:1;
  color: #aaaaaa;
  margin:0 auto;
  &.paid, &.success{
    color: $brand-primary;
    border-color: $brand-primary;
  }
  &.unpaid, &.danger{
    color: $brand-danger;
    border-color: $brand-danger;
  }
}
.strong {
  font-weight: bold;
}
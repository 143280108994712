/*
 * We are hiding the invisible nav outside the screen
 * so we need to avoid the horizontal scroll
 */
body.okayNav-loaded {
  overflow-x: hidden;
}
.okayNav-header {
  //position: fixed;
  top: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  //width: 100%;
  //padding: 0 15px;
  //-webkit-align-items: center;
  //-ms-flex-align: center;
  //align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}
.okayNav:not(.loaded) {
  visibility: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.okayNav ul {
  /* We want two navigations - one hidden and one visible */
  float: left;
}
.okayNav a {
  position: relative;
  z-index: 1;
}
.okayNav__nav--visible a .fa {
  text-align: center;
  display: block;
  font-size: 20px;

  margin-bottom: 5px;
}
.okayNav__nav--visible {
  overflow: hidden;
  white-space: nowrap;

  margin-top: -5px;
}
.okayNav__nav--visible li {
  display: inline-block;
}
.okayNav__nav--visible a {
  /* Link styling for the visible part of the nav */
  display: block;
  padding: 15px 15px;
  transition: color 200ms cubic-bezier(0.55, 0, 0.1, 1);
}
.okayNav__nav--visible:empty ~ .okayNav__menu-toggle {
  //top: 0;
}
/* Link styling for the off-screen part of the nav */
.okayNav__nav--invisible {
  position: absolute;
  overflow-y: hidden;
  //position: fixed;
  //top: 7rem;
  //bottom: 0;
  //overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  //margin-top:77px;
  top:106px;
  padding: 0;
  width: 100%;
  z-index: 111;
  //text-align: left;
}
.okayNav__nav--invisible a {
  display: block;
  //width: 200px;
  width: 100%;
  padding: 15px;
  //background: #fff;
}
.okayNav__nav--invisible a .fa {
  // margin-left: 5px;
}
.okayNav__nav--invisible.nav-left {
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.okayNav__nav--invisible.nav-right {
  right: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.okayNav__nav--invisible.transition-enabled {
  transition: -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
  transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
  transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
}
.okayNav__nav--invisible.nav-open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
/* Kebab icon */
.okayNav__menu-toggle {
  position: relative;
  z-index: 1;
  top: 15px;
  float: left;
  width: 100px;
  height: 20px;
  cursor: pointer;
  //transition: -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
  //transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
  //transition: transform 400ms cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 400ms cubic-bezier(0.55, 0, 0.1, 1);
}
.okayNav__menu-toggle.okay-invisible {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  opacity: 0;
}
.okayNav__menu-toggle span {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 4px;
  margin: auto;
  pointer-events: none;
  border-radius: 50%;
}
.okayNav__menu-toggle span:nth-child(1) {
  top: 0;
}
.okayNav__menu-toggle span:nth-child(2) {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.okayNav__menu-toggle span:nth-child(3) {
  bottom: 0;
}
.okayNav__menu-toggle.icon--active {
  /* Kebab icon when off-screen nav is open */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

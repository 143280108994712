
.table-search-field {

  &:before{
    content: "\f002";
    position: absolute;
    left: 25px;
    top: 8px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    color: #aaaaaa;
  }
  input{
    padding-left: 35px;

  }

}
.table-responsive{
  background-color: white;
  border: 1px solid #ccc;
  margin: 10px auto 25px;
  .table{
    .checkbox{
      position: relative;
      display: block;
      margin-top: -31px;
      margin-bottom: 0;
      label{
        min-height: auto;
        .replacer{
          margin-right: 0;
        }
      }
    }
    td .checkbox{
      margin-top: 0;
    }
    margin-bottom: 0;

    th{
      border-top:0;
      background: -webkit-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
      background: -moz-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
      background: -o-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
      background: -ms-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
      background: linear-gradient(0deg, #efefef 0%, #fefefe 100%);
    }
  }

}
.checkbox-col{
  width:20px;
}
.filtered{
  th{
    cursor: pointer;
    &:before{
      content: "\f0dc";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px;
      color:#aaa;
      padding-right:10px;

    }
    &:first-child:before{
      content: "";
    }

  }
  th[data-ordering=asc]:before{
    content: "\f0de";
    color: $brand-primary;
  }
  th[data-ordering=desc]:before{
    content: "\f0dd";
    color: $brand-primary;
  }
  th[data-ordering=desc], th[data-ordering=asc]{
    color: $brand-primary;
  }
}
.table-responsive{
  overflow-x: visible;

}
.table-responsive .btn-group {
  float: right;
  margin: 0 0;
}
.pagination{
  margin:0;
}

 @media (min-width: 760px) {
   .table-responsive {
     overflow: inherit!important;
   }
}
$form-container-background: #f4f5f7;
$form-container-padding: 20px;
$form-container-title-font-size: 18px;
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  max-width: 95%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  .close {
    margin-right: 14px;
    margin-top: 4px;
    position: absolute;
    right: -9px;
    top: -5px;
    z-index: 1;
    opacity: 0.6;
  }
}

.well {
  display: inline-block;
  width: 100%;
  .text-overflow {

    .bg-well {
      background-color: $well-bg;
      padding: 5px 0;

    }
  }
}

.btn-primary:focus, .btn-primary:active {
  background-color: #0fa00f;
  border-color: #0b8045;
}

.btn-primary:hover {
  background-color: #0b8045;
  border-color: #0b8045;
}

.btn {
  outline: 0 !important;
}

.mgr-5 {
  margin-right: 5px;
}

.mrg-0 {
  margin: 0 !important;
}

.text-overflow {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  z-index: 555;
  position: relative;
  &:hover {
    overflow: visible;
  }
}

.list-row .checkbox label {
  min-height: 31px;
}

.label {
  font-weight: normal;
  padding: 6px;
  font-size: 13px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

}

.well-title {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  line-height: 1;
  margin-bottom: 45px;
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
}

.loader-wrapper {
  position: absolute;
  height: 2px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.loader-inner {
  width: 30%;
  height: 2px;
  background-color: $brand-primary;
}

.text-list {
  .list-row {
    padding: 10px 0;

  }
}

.ns-zone {
  label {
    &[aria-expanded="false"] {
      &:after {
        content: "Add IP";
      }
    }
    &[aria-expanded="true"] {
      &:after {
        content: "Remove IP";
      }
    }
  }
}
.well .checkbox-inline.full-inline {
  padding-top: 0;
  min-height: 0;

}
.same-as{
  .well-title{
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;

  }
}
datalist {
  display: none;
}
.additional-ns:before{
  content: "Add Additional NS";
}
.additional-ns.active:before{
  content: "Remove Additional NS";
}

.ns-zone label.additional-ns.active:after, .ns-zone label.additional-ns:after{
  content: "";
}
.same-as{
  .contact-group{
    display: none;
  }
}
.contact{
  &.admin-contact.same-as-registrant{
    .form-group{
      display: none;
    }

    &.is-entity {
      .form-group.admin_n, .form-group.admin_l-n{
        display: block;
      }

    }
    .form-group.admin_e{
      display: block;
    }
    .form-group.admin_p{
      display: block;
    }
  }
}
.ns-zone .has-error .validation-icon,.ns-zone  .has-success .validation-icon{
  right: -35px;
}
.ns-zone input{
  padding-right: 10px!important;
}
.ns-zone .has-error label.btn, .ns-zone  .has-success label.btn{
  color:$brand-primary!important;
}
.ns-zone .has-error .input-group-btn .validation-icon, .ns-zone .has-success  .input-group-btn .validation-icon{
  display: none;
}

#configure-total.affix{
  top:0;
}
.p-btm{
  padding: 5px 0 10px 0;
}

.search-area {
  max-width: 770px;
  margin: 0 auto;
  padding-bottom: 60px;
  .label-title {
    font: bold 26px "PT Sans", sans-serif;
  }
  .search-field {
    .form-horizontal {
      width: 100%;
      .bulk.input-group {
        width: 100%;

      }

    }
    input[name=domain] {
      padding-right: 100px;

    }
    position: relative;

    .bulk-btn {
      background-color: #bfbfbf;
      border-radius: 3px;
      color: #ffffff;
      font-size: 12px;
      padding: 10px;
      border: none;
      margin: -38px 6px 6px 0;
      z-index: 15;
      float: right;
      line-height: 1;
      position: relative;
      right: 0;
    }
  }
  .left-normal {
    @media screen and (max-width: 991px) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
}

.with-shadow {
  border: 10px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.btn-gradient, .btn-gradient:focus {
  background: linear-gradient(0deg, #efefef 0%, #fefefe 100%);
  color: $brand-primary;
  border: 1px solid #ccc;
  outline: none;
}

.btn-gradient:hover, .btn-gradient:active {

  background: linear-gradient(0deg, #fefefe 0%, #efefef 100%);
  color: $brand-primary;
  border: 1px solid #ccc;
  outline: none;
}

.list-row, .table-responsive {
  border-bottom: 1px solid #ccc;
  .price-text{
    line-height: 45px;
  }
  .radio, .checkbox{
    margin-bottom: 0;
  }

  .dropdown-menu{
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    li{
      &:first-child a, &:last-child a{
        border: 0;
      }
      a {
        display: block;
        padding: 3px 20px;
        clear: both;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
        &:hover{
          color: #262626;
          text-decoration: none;
          background-color: #f5f5f5
        }
      }
    }
  }
  .btn-group{
    float: right;
    margin:4px 0;
    .btn-sm{
      padding: 5px 10px;
      font-size: 18px;
    }
  }
  .label {
      margin: 10px auto;
      display: inline-block;
  }
  .loader div
  {
    width:80px;
    height: 0;
    margin: -127px 0 0 0;
    @media screen and (max-width: $screen-sm-min) {
      margin:-178px 0 0 30%;
    }
  }
}
.dom-status{
  text-align: center;
}
.dom-action {

  .btn-list{
    float: right;
  }

}
.btn-list{
  margin-top:20px;
  &.top-3{
    margin-top: 3px;
  }
  .btn {
    margin: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
  .btn-xs{
    margin-top: 11px;
    margin-right:0;
  }
}
.bolder {
  font-weight: bold;
}
.mrg-5-0 {
  margin:5px 0!important;
}
.btn-primary-outline, .btn-primary-outline:focus, .btn-primary-outline:active, .btn-primary-outline.active, .btn-primary-outline.active:focus, .btn-primary-outline.active:active, .btn-primary-outline:active:hover, .btn-primary-outline.active:hover, .btn-primary-outline.active:focus:hover{

  box-sizing: border-box;
  color: $brand-primary!important;
  background-color: transparent;
  background-image: none;
  border: 2px solid $brand-primary;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  border-radius:4px;
  outline: 0!important;
  height: 100%;
   &.btn-xs{
    border: 1px solid $brand-primary;
    border-radius:3px;
  }
  &.btn-sm{
    padding: 6px 25px;
  }

  &:hover{
    background-color:$brand-primary;
    color:white!important;
    text-decoration: none;
    outline: 0;
  }
}
#cart-zone.affix{
  top:20px;
}


/*****cart****/

.steps {
  overflow: hidden;
  margin-bottom: 30px;
  li{
    border: 1px solid #ceccd3;
    border-right-width: 0;
    border-left-width: 0;
    box-sizing: border-box;
    background: -webkit-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
    background: -moz-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
    background: -o-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
    background: -ms-linear-gradient(90deg, #efefef 0%, #fefefe 100%);
    background: linear-gradient(0deg, #efefef 0%, #fefefe 100%);
    overflow: hidden;
    position: relative;
    &:first-child{
      &:after{
        display: none;
      }
      border-left-width: 1px;
    }
    &:last-child{
      border-right-width: 1px;
    }
    &:after{
      top:0;
      left:-26px;
      width: 44px;
      height: 100%;
      display: block;
      content: "";
      position: absolute;
      border:4px solid #ccc;
      -moz-transform: rotate(45deg); /* Для Firefox */
      -ms-transform: rotate(45deg); /* Для IE */
      -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
      -o-transform: rotate(45deg); /* Для Opera */
      transform: rotate(45deg);
      background: -webkit-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -moz-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -o-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -ms-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: linear-gradient(0deg, #0f990f 0%, #05b305 100%);
    }
    &.active{
      border-color:  #0b8045;
      background: -webkit-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -moz-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -o-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: -ms-linear-gradient(90deg, #0f990f 0%, #05b305 100%);
      background: linear-gradient(0deg, #0f990f 0%, #05b305 100%);
      color:white;
      &:after{
        border-color: #0d902a;
      }
      a{
        color:white;
      }
    }
    a{
      font-weight: bold;
      padding-top: 12px;
      line-height:1;
      padding-bottom: 12px;
      display: block;
    }
  }
}
.btn-empty-add{
  max-width: 320px;
  margin:60px auto;
}
.empty-img{
  padding-left: 85px;
}
.pdng-10-0{
  padding:10px 0;
}

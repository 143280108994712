.pageContent {
  text-align: left;
}

.ccpDomainDiv {
  padding: 5px 10px;
  text-decoration: none;
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 7px 0px 7px 0px;
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
  background-color: #F0F0F0;
}

.ccpDomainflag {
  float: right;
  height: 38px;
}

.ccpDomainName {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}

.ccpDomainDescrtiption {
  font-size: 13px;
  color: #777;
  display: block;
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ccpRegionName {
  font-size: 25px;
  font-weight: bold;
  margin: 5px 5px 10px 5px;
  clear: left;
  padding: 10px 0px 10px 0px;
  border-bottom: 2px dashed #ddd;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.ccpDomainflag img {
  width: 24px;
  height: 24px;
}
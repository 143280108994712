
.title {
  background: #0fa00f url(../../img/title/text_bar2.png);
  border-top: 1px solid #e7e6e6;
  border-bottom: 1px solid #e7e6e6;
  //overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  &.home h2 {
    margin: 0;
  }
  &:before {
    content: '';
    display: block;
    border-top: 5px solid #fff;
    height: 29px;
    background: url(../../img/title/text_bar_shadow2.png) center top no-repeat;
  }
  &:after {
    content: '';
    display: block;
    border-top: 5px solid #fff;
    margin-top: 17px;
  }
  .p-title, h2.p-subtitle {
    text-shadow: #2c2c2c 0 1px 1px;
    color: #fff;
    font: bold 22px 'PT Sans';
    float: left;
    margin-top: 0px;
    margin-bottom: 0;

  }
  h2.p-subtitle {
    font-weight: normal;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 5px;
    color: white;
    width: 100%;
  }
  .container-fluid {
    position: relative;
  }
  .breadcrumb {
    background: none transparent;
    position: absolute;
    bottom: 10px;
    right: 15px;
    margin: 0;
    padding: 0;
    display: block;
    li, li a, .active {
      color: #fff;
      font-size: 10px;
    }

  }
}

@media (max-width: $container-md) {
  .title .breadcrumb {
    position: relative;
  }
}

/**********  Dashboard Title *******************/
.dashboard-welcome {
  float: left;
  position: relative;
  font: 16px "PT Sans", sans-serif;
  color: white;
  text-shadow: none;
  line-height: 1.5;

  span {
    color: white;
    display: inline-block;
    font-size: 20px;
    margin: 5px;
  }
  p {
    &#balance {
      font: bold 18px "PT sans", sans-serif;
      margin: 0;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}

.dashboard-menu {
  margin-top: 7px;
  color: white;
  float: right;
  ul {
    margin: 0;
    &.list-inline > li {
      padding-left: 15px;
      padding-right: 15px;
    }
    li {
      display: inline-block;
    }
    .icon-item {
      a {
        color: white;
        text-decoration: none;
        .fa {
          display: block;
          font-size: 20px;
        }
        text-align: center;
        display: block;
        float: left;
        .description {
          font-size: 13px;
        }
      }

    }
  }

}

.page-title {
  border-bottom: 1px #ccc solid;
  margin-bottom: 25px;
  h2 {
    font-size: 30px;
    font-weight: 400;
  }
}

.well-table-title {
  font-weight: bold;
  .search {
    text-align: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
  }
}

.dashboard-welcome {
  .dropdown-menu{
    position: absolute;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    li{
      &:first-child a, &:last-child a{
        border: 0;
      }
      a {
        display: block;
        padding: 3px 20px;
        clear: both;
        background-color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
        &:hover{
          color: #262626;
          text-decoration: none;
          background-color: #f5f5f5
        }
      }
    }
  }
  .dropdown {
    position: initial;
  }
  .dropdown-menu {
    top: initial;
  }
}

.transfer-well{
  .modal-body{
    overflow: hidden;
  }
  .list-row .price-text {
    line-height: 64px;
    text-align: center;
    display: block;
  }
  &.well .list-row .checkbox{
    margin: 20px 0;
  }
  .col-sm-7.muted-transfer{
    padding-left: 15px;
  }
  .list-row .label{
    margin: 20px auto;
  }
  .trf-btn,.price-sender{
    padding: 5px 15px;
  }
  .transfer-code{
    max-width: 170px;
    float: left;
    margin-right: 10px;
  }
  .muted-transfer{
    font-size:14px;
    color:#938f9d;
    padding: 15px 0;
  }
  .dom-status{
    text-align: left;
  }
}
.transfer-well .muted-transfer.pdng-20 {
  padding-top: 20px;
}
.transfer-steps {
  counter-reset: section;
  overflow: hidden;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  li{
    float: left;
    width:33.3333%;
    display:table;
    margin: 20px auto;
    &:nth-child(2){
      padding-left: 42px;
      @media screen and (max-width: 1041px) {
        padding-left:0;
      }
    }
    span {
      display: table-cell;
      vertical-align:middle;
      height: 88px;
      text-align: left;
      width: 100%;
    }
    @media screen and (max-width: 1041px) {
      float: none;
      width:100%;
    }
    &:before{
      counter-increment: section;
      content: counter(section);
      display: block;
      border: $brand-primary 4px solid;
      -webkit-border-radius:50%;
      -moz-border-radius:50%;
      border-radius:50%;
      height: 88px;
      width: 88px;
      float: left;
      color: $brand-primary;
      font-size: 48px;
      line-height:80px;
      text-align: center;
      margin-right:10px;
    }
  }
}
#transferWithDocument{
  .well {
    margin-bottom: 0;
  }
}
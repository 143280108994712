
.dropdown-submenu {
  position: relative;
}

.navbar-default .navbar-nav > li > a.green-menu {
  color: #0fa00f;
}

.dropdown-submenu > .dropdown-menu {
  li:first-child a {
    border-top: 1px solid #b9d9e5;
  }

  top: 0;
  left: 100% !important;
  margin-top: -5px;
  margin-left: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

@media (max-width: $grid-float-breakpoint) {
  .dropdown-submenu > .dropdown-menu {
    margin-top: -5px !important;
    a {
      padding-left: 50px !important;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
   .navbar-default #top-nav .open > .dropdown-menu {
    display: none;
  }
  .navbar {
    margin-top: 15px;
  }

  .nav > li {
    padding-top: 34px;
  }

  ul.nav li:hover > ul.dropdown-menu {
    display: block;
    position: absolute;
    left: 17px;
    background: transparent;
  }

  .dropdown-submenu.pull-left > .dropdown-menu {
    left: -100% !important;
    margin-left: 10px;
  }

  #top-nav {
    text-align: center;
  }
}

.nav > li {
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 17px;
  a {
    &:after {
      content: '';
      background: url("../../img/sprite.png") 6px 11px no-repeat;
      float: right;
      display: block;
      width: 12px;
      height: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    font-size: 14px;
    padding: 0;
  }
}

.navbar-default {
  border: none;
}

.navbar-right {
  margin-top: 5px;
}

.nav {
  .dropdown-menu {
    padding: 5px 0 0 0;
    li:first-child>a {
      border-top: 1px solid #22b222;
    }
    li:last-child>a {
      border-bottom: 1px solid #22b222;
    }
    li > a {
      white-space: normal;
      &:hover {
        color: #0fa00f;
        background-color: #fff;
      }
      background-color: rgb(246, 243, 243);
      padding: 14px 16px 14px 12px;
      min-width: 130px;
      display: block;
      border: 1px solid #dbe8ed;
      border-bottom: 1px solid #e3e3e3;
      border-top: 1px solid #fff;
      color: #434242;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.25;
      &:after {
        content: '';
        display: none;
      }
    }
    .dropdown-submenu {
      a {
        &:after {
          content: '';
          background: url("../../img/sprite.png") 6px 7px no-repeat;
          float: right;
          display: block;
          width: 12px;
          height: 12px;
        }
      }
      .dropdown-menu a {
        &:after {
          content: '';
          display: none;
        }
      }
    }
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent;
  color: inherit;
}

.navbar-default .open > .dropdown-menu {
  display: block;
}

.navbar-default .navbar-nav > li > a:focus {
  color: inherit;
}

.navbar-default .navbar-nav > li > a:hover {
  color: $brand-primary !important;
}

.navbar-default .navbar-nav > li > a[href="#"] {
  cursor: default;
}


